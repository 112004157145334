import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import {
  fetchBankAccounts,
  fetchEconomyData,
  fetchTransactions,
  fetchKeyFiguresSummary,
  removeAttachment,
  } from './dashboardAPI'

export const intialDashboardState = {
  economy: {
    status: 'idle',
    data: {
      income: {},
      expenditure: {},
      result: {},
    },
    error: null,
  },
  transactions: {
    status: 'idle',
    data: [],
    meta: { total_pages: 1 },
    error: null,
  },
  keyFiguresSummary: {
    status: 'idle',
    data: {},
    error: null,
  },
  bankAccounts: {
    status: 'idle',
    data: [],
    error: null,
  }
}

export const fetchBankAccountsAsync = createAsyncThunk(
  'dashboard/fetchBankAccounts',
  async () => {
    const response = await fetchBankAccounts()
    return response.data
  }
)


export const fetchEconomyChartAsync = createAsyncThunk(
  'dashboard/fetchEconomyChartAsync',
  async () => {
    const response = await fetchEconomyData(false, true)
    return response.data
  }
)

export const fetchTransactionsAsync = createAsyncThunk(
  'dashboard/fetchTransactions',
  async (params) => {
    const response = await fetchTransactions(
      params.accountNumber,
      params.page,
      params.missingAttachment
    )
    return response.data
  }
)

export const fetchKeyFiguresSummaryAsync = createAsyncThunk(
  'dashboard/fetchKeyFiguresSummary',
  async () => {
    const response = await fetchKeyFiguresSummary(true, true)
    return response.data
  }
)

export const removeAttachmentAsync = createAsyncThunk(
  'dashboard/removeAttachment',
  async (params) => {
    const response = await removeAttachment(
      params.voucherId,
      params.attachmentId
    )
    return response.data
  }
)

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: intialDashboardState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBankAccountsAsync.pending, (state) => {
        state.bankAccounts.status = 'loading'
      })
      .addCase(fetchBankAccountsAsync.fulfilled, (state, action) => {
        state.bankAccounts.status = 'succeeded'
        state.bankAccounts.data = action.payload
      })
      .addCase(fetchBankAccountsAsync.rejected, (state, action) => {
        state.bankAccounts.status = 'failed'
        state.bankAccounts.error = action.error.message
      })
      .addCase(fetchEconomyChartAsync.pending, (state) => {
        state.economy.status = 'loading'
      })
      .addCase(fetchEconomyChartAsync.fulfilled, (state, action) => {
        state.economy.status = 'succeeded'
        state.economy.data = action.payload
      })
      .addCase(fetchEconomyChartAsync.rejected, (state, action) => {
        state.economy.status = 'failed'
        state.economy.error = action.error.message
      })
      .addCase(fetchTransactionsAsync.pending, (state) => {
        state.transactions.status = 'loading'
      })
      .addCase(fetchTransactionsAsync.fulfilled, (state, action) => {
        state.transactions.data = action.payload.records
        state.transactions.meta = action.payload.meta
        state.transactions.status = 'succeeded'
      })
      .addCase(fetchTransactionsAsync.rejected, (state, action) => {
        state.transactions.status = 'failed'
        state.transactions.error = action.error.message
      })
      .addCase(fetchKeyFiguresSummaryAsync.pending, (state) => {
        state.keyFiguresSummary.status = 'loading'
      })
      .addCase(fetchKeyFiguresSummaryAsync.fulfilled, (state, action) => {
        state.keyFiguresSummary.status = 'succeeded'
        state.keyFiguresSummary.data = action.payload
      })
      .addCase(fetchKeyFiguresSummaryAsync.rejected, (state, action) => {
        state.keyFiguresSummary.status = 'failed'
        state.keyFiguresSummary.error = action.error.message
      })
      .addCase(removeAttachmentAsync.fulfilled, (state, action) => {
        const transactions = [...state.transactions.data]
        const index = transactions.findIndex(transaction => transaction.id === action.payload.id)
        if (index !== -1) {
          transactions[index].attachments = action.payload.attachments
          transactions[index].need_attachment = transactions[index].attachments.length === 0
          state.transactions.data = transactions
        }
        state.transactions.status = 'succeeded'
      })
      .addCase(removeAttachmentAsync.rejected, (state, action) => {
        state.transactions.status = 'failed'
        state.transactions.error = action.error.message
      })
  },
})

export const selectBankAccountsData = (state) => state.dashboard.bankAccounts.data;
export const selectBankAccountsStatus = (state) => state.dashboard.bankAccounts.status;

export const selectEconomyData = (state) => state.dashboard.economy.data;
export const selectEconomyStatus = (state) => state.dashboard.economy.status;
export const selectEconomyError = (state) => state.dashboard.economy.error;

export const selectTransactions = (state) => state.dashboard.transactions;
export const selectTransactionsStatus = (state) => state.dashboard.transactions.status;
export const selectTransactionsError = (state) => state.dashboard.transactions.error;

export const selectKeyFiguresSummaryData = (state) => state.dashboard.keyFiguresSummary.data;
export const selectKeyFiguresSummaryStatus = (state) => state.dashboard.keyFiguresSummary.status;
export const selectKeyFiguresSummaryError = (state) => state.dashboard.keyFiguresSummary.error;

export default dashboardSlice.reducer;
