import React from 'react';
import i18n from '../i18n'
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button'
import ClearIcon from '@mui/icons-material/Clear'
import Grid from '@mui/material/Unstable_Grid2'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';
import { Document, Page } from 'react-pdf'
import Loader from './Loader';

import './fileUploader.scss';

const maxFileSizeInMegaBytes = 5;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #ccc',
  boxShadow: 24,
  p: 4,
};

export default class FileUploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      fileName: ''
    }

    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.renderPdf = this.renderPdf.bind(this);
    this.renderImage = this.renderImage.bind(this);
    this.renderFilePreview = this.renderFilePreview.bind(this);
  }

  onDocumentLoadSuccess(doc, index) {
    const files = this.state.files;
    files[index].numPages = doc.numPages;
    this.setState({ files: files });
  }

  handleChange(event) {
    const newFiles = event.target.files;
    const files = this.state.files;

    for (let i = 0; i < newFiles.length; i++) {
      const sizeInMb = newFiles[i].size / 1024 / 1024;
      if (sizeInMb > maxFileSizeInMegaBytes) {
        files.push({
          error: {
            fileName: newFiles[i].name,
            messageKey: 'validations.fileTooLarge',
          }
        })
      } else {
        files.push({
          file: newFiles[i],
          contentType: newFiles[i].type
        });
      }
    }

    this.setState({ files: files });
  }

  handleSubmit() {
    const { onUpload } = this.props;

    if (this.state.files.some(file => file.hasOwnProperty('error'))) {
      const errorElement = document.getElementsByClassName('file-validation-error')[0];
      if (errorElement) errorElement.scrollIntoView();
      return;
    }

    onUpload && onUpload(this.state.files, this.state.fileName);

    this.setState({files: []})
  }

  handleDelete(index) {
    const files = this.state.files;
    files.splice(index, 1);
    this.setState({ files: files });
  }

  handleClose() {
    const { onClose } = this.props;

    this.setState({ files: [] });

    onClose && onClose(false);
  }

  renderActions() {
    const { loading } = this.props;

    if (loading) {
      return (
        <Loader
          style={{marginLeft: 10}}
          size={30}
        />
      )
    }

    if (this.state.files.length === 0) {
      return null;
    }

    return (
      <button
        onClick={this.handleSubmit}
        style={{
          fontSize: 12,
          float: 'left',
          padding: '10px',
        }}
        variant="outlined"
        className="bg-emerald-500 text-white p-2 rounded"
      >
        Spara
      </button>
    )
  }

  renderPdf(file, index) {
    const { files } = this.state;

    return (
      <React.Fragment>
        <Document
          file={file}
          onLoadSuccess={(doc) => this.onDocumentLoadSuccess(doc, index)}
        >
          <Page
            height={600}
            width={600}
            pageNumber={1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        </Document>
        <p>Sida 1 av {files[index].numPages}</p>
      </React.Fragment>
    )
  }

  renderImage(file) {
    const fileReader = new FileReader();

    fileReader.onload = function(){
      const imgPreview = document.getElementById(file.name);
      // if (imgPreview) {
        imgPreview.src = fileReader.result;
      // }
    };

    fileReader.readAsDataURL(file);

    return (
      <img id={file.name} width={600} alt="attachment"/>
    )
  }

  renderFilePreview(file, contentType, index) {
    const t = i18n.getFixedT();

    if (file.error) {
      return (
        <div className="file-validation-error">
          <p>{`${file.error.fileName}: ${t(file.error.messageKey)}`}</p>
        </div>
      )
    }

    if (!file.file) {
      return;
    }

    if (contentType === 'application/pdf') {
      return this.renderPdf(file.file, index);
    } else if (contentType === 'image/png' || contentType === 'image/jpeg') {
      return this.renderImage(file.file)
    } else {
      return <p style={{color: "#000", fontSize: '16px'}}>{file.file.name}</p>
    }
  }

  render() {
    const {
      open,
      multiple,
      loading,
      title,
      includeFileNameInput,
    } = this.props;

    const { files } = this.state;

    return (
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{margin: '5px 0 15px 0'}}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <span className="modal-title">{title || 'Ladda upp'}</span>
                <ClearIcon
                  onClick={this.handleClose}
                  style={{
                    float: 'right',
                    cursor: 'pointer'
                  }}
                />
              </Typography>
            </Grid>

            {loading ?
              <Loader
                size={100}
                style={{margin: '0 auto'}}
              />
            :
              <React.Fragment>
                {includeFileNameInput &&
                  <Grid item xs={12} style={{margin: '5px 0 15px 0'}}>
                    <TextField
                      fullWidth={true}
                      label='Filnamn'
                      variant='outlined'
                      onChange={(event) => this.setState({ fileName: event.target.value })}
                      value={this.state.fileName}
                    />
                  </Grid>
                }
                <Grid item xs={4} style={{margin: '5px 0 15px 0'}}>
                  <button
                    variant="outlined"
                    className="bg-emerald-500 text-white p-4 rounded"
                  >
                    <input
                      onChange={(event) => this.handleChange(event)}
                      type="file"
                      id="file-upload"
                      accept={this.props.accept || "application/pdf,image/png,image/jpeg,.doc,.docx"}
                      hidden
                      multiple={multiple}
                      style={{cursor: 'pointer'}}
                    />
                    <label htmlFor="file-upload" className="choose-file-label" style={{cursor: 'pointer'}}>
                      Välj filer
                    </label>
                  </button>
                </Grid>

                <Grid item xs={10}>
                  { this.renderActions() }
                </Grid>

                <Grid item xs={12}>
                  <div className="file-preview-container">
                    {
                      files.map((file, i) =>
                        <div className="file-preview-row" key={i}>
                          { this.renderFilePreview(file, file.contentType, i) }
                          <Button
                            variant="outlined"
                            style={{background: '#ef4444', color: '#fff'}}
                            onClick={() => this.handleDelete(i)}
                          >
                            Ta bort
                          </Button>
                        </div>
                      )
                    }
                  </div>
                </Grid>
              </React.Fragment>
            }
          </Grid>
        </Box>
      </Modal>
    );
  }
}

